// Alpine
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import Plyr from 'plyr';

// Initialize alpine
Alpine.plugin(persist)

// Plugins

// Extend webpack window classes

// Add to window
window.Plyr = Plyr;
window.Alpine = Alpine;
window.Alpine.start();
